<template>
  <div class="tokyo_tm_about">
    <div class="about_image">
      <img src="../assets/img/slider/1.jpg" alt="about" />
    </div>
    <!-- End .about_image -->

    <div class="description">
      <h3 class="name">Дмитрий Никифоров, консультант, бизнес-тренер</h3>
      <div class="description_inner">
        <div class="left">
          <p>
            Привет! Я Дмитрий, предприниматель с философским подходом к жизни.
            Не боюсь набивать шишки и делиться с людьми результатами. И так уже с 2007 года. 
            Люблю точные и экстремальные виды спорта, играю на ударных.
            Самый большой драйв в жизни получаю от результатов людей, которым я помог вырасти.
          </p>
          <div class="tokyo_tm_button">
            <button class="ib-button" @click="showModal">Подробнее</button>
          </div>
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <p><span>Родился:</span>11.1970</p>
            </li>
            <li>
              <p><span>Возраст:</span>52</p>
            </li>
            <li>
              <p><span>Живу:</span>г. Иннополис, Татарстан</p>
            </li>
            <li>
              <p>
                <span>Email:</span
                ><a href="mailto:vip@nikiforov.guru">vip@nikiforov.guru</a>
              </p>
            </li>
<!--            <li>
              <p>
                <span>Phone:</span
                ><a href="tel:+770221770505">+77 022 177 05 05</a>
              </p>
            </li>  -->
            <li>
              <p><span>Telegram:</span>
              <a href="https://t.me/nikiforov_guru">@nikiforov_guru</a></p>
            </li>
            <li>
              <p><span>Работаю:</span>Очно, дистанционно</p>
            </li>
          </ul>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModal"
        v-if="isVisible"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
        >
          <div class="modal-content">
            <div class="tokyo_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                  class="svg"
                  src="../assets/img/svg/cancel.svg"
                  alt="cancel-img"
                />
              </div>
              <!-- End .close -->
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>Профессиональные навыки</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="tokyo_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Подготовка к переговорам</span
                        ><span class="number">95%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                        ><span class="label">Обучение переговорам (группа)</span
                        ><span class="number">90%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:90%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                        ><span class="label">Управленческие поединки</span
                        ><span class="number">95%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                  </div>
                  <!-- End .tokyo_progress -->
                </div>
                <!-- End .left -->
                <div class="right">
                  <div class="about_title">
                    <h3>Эффективность форматов</h3>
                  </div>
                  <div class="tokyo_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Персональная подготовка онлайн</span
                        ><span class="number">95%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:95%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Очная подготовка (выезд)</span
                        ><span class="number">90%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:90%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Очный тренинг (группа)</span
                        ><span class="number">85%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:85%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End .right -->
              </div>
              <div class="counter">
                <div class="about_title">
                  <h3>Факты</h3>
                </div>
                <ul>
                  <li>
                    <div class="list_inner">
                      <h3>31</h3>
                      <span>Год ведения собственного бизнеса</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>16K</h3>
                      <span>Сотрудников у крупнейшего заказчика</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>12</h3>
                      <span>Дней длился самый длинный тренинг</span>
                    </div>
                  </li>
                </ul>
                <!-- End ul -->
              </div>
              <!-- End .counter -->
              <div class="partners">
                <div class="about_title">
                  <h3>Мои партнёры</h3>
                </div>
                <Brand />
              </div>
              <!-- End .partner -->
            </div>
            <!-- End .tokyo_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
</template>

<script>
import Brand from "./Brand";
export default {
  components: {
    Brand,
  },
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function() {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss"></style>
